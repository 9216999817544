import API from '@/plugin/points-manager/service/api'
import { ElMessage } from 'element-plus'

export const uploadFileMixin = async (file, type, beforeCallBack, afterCallBack) => {
  if (typeof beforeCallBack === 'function') {
    beforeCallBack()
  }
  let res = await API.uploadFileAPI(
    {
      file: file.file,
      filename: file.fileName,
    },
    { businessType: type },
  ).catch(err => {
    ElMessage.error(err.message)
  })
  if (res.code == 200) {
    if (typeof beforeCallBack === 'function') {
      afterCallBack(res.data.fileUrl)
    }
  } else {
    ElMessage.error(res.msg)
  }
}

export const uploadfileRuleMixin = uploadRule => {
  return new Promise(async (resolve, reject) => {
    let res = await API.uploadFileRuleAPI({
      businessType: uploadRule.businessType,
    })
    if (Number(res.code) == 200) {
      resolve({
        ...res.data,
        businessType: uploadRule.businessType,
      })
    } else {
      reject(res.msg)
    }
  })
}
